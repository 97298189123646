/* --- WHITE --- */
.txtWhite {
  color: #ffffff;
}
.bgWhite {
  background-color: #ffffff;
}

/* --- BLACK --- */
.txtBlack {
  color: #292a36;
}
.bgBlack {
  background-color: #292a36;
}

/* --- LIGHT GREY --- */
.txtLightGrey {
  color: #97979d;
}
.bgLightGrey {
  background-color: #97979d;
}

/* --- DARK GREY --- */
.txtDarkGrey {
  color: #6c6d79;
}
.bgDarkGrey {
  background-color: #6c6d79;
}

/* --- GREEN --- */
.txtGreen {
  color: #33c100;
}
.bgGreen {
  background-color: #33c100;
}

/* --- DARK GREEN --- */
.txtDarkGreen {
  color: #2c8f07;
}
.bgDarkGreen {
  background-color: #2c8f07;
}

/* --- TEAL --- */
.txtTeal {
  color: #008b8b;
}

.bgTeal {
  background-color: #008b8b;
}

/* --- ORANGE --- */
.txtOrange {
  color: #f06c30;
}

.bgOrange {
  background-color: #f06c30;
}

/* --- MAROON --- */
.txtMaroon {
  color: #8c0e51;
}

.bgMaroon {
  background-color: #8c0e51;
}
