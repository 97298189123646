.controlsContainer {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 40%;
  padding: 1rem 0;
  background-color: #e0e0e8;
  border: 2px solid #84899b;
  font-weight: bold;
  position: relative;
  font-size: 18px;
  padding: 1rem;
}

.positiveButton::before,
.negativeButton::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1rem;
}

.positiveButton::before {
  background-color: black;
}
.negativeButton::before {
  background-color: #84899b;
}
