.relatedDescription {
  line-height: 1.4;
  letter-spacing: -0.23px;
}

.underline {
  position: relative;
  padding-bottom: 0.2rem;
  border-bottom: 2px solid #33c100;
  transition: padding-bottom 0.3s ease;
}

.underline:hover {
  padding-bottom: 0;
}

.underlineSubtitle {
  text-decoration: underline;
}

.relatedSubtitle {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.42px;
}

.subtitle {
  font-size: 18px;
}

@media only screen and (min-width: 760px) {
  .relatedDescription {
    line-height: 1.4;
    letter-spacing: -0.23px;
  }

  .subtitle {
    font-size: 20px;
  }
}
